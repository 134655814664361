import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header'
import Footer from '../containers/layout/footer'
// import PageHeader from '../components/pageheader'
import JobApplicationArea from '../components/forms/job-form'
import ContactArea from '../containers/global/contact-area'

const CareersPage = ({ pageContext, location }) => (
    <Layout location={location}>
        <SEO title="job application" />
        <Header />
        <main className="site-wrapper-reveal">
            <JobApplicationArea/>
            <ContactArea />
        </main>
        <Footer />
    </Layout>
)

export default CareersPage
